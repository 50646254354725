<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @keyup.enter="onFilter()"/>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th class="w-200px">Tanggal</th>
                            <th class="w-200px">Judul</th>
                            <th class="w-200px">Kode PO</th>
                            <th class="w-200px">Kode Request PO</th>
                            <th class="w-200px">Warehouse</th>
                            <th>Author</th>
                            <th class="w-100px text-end">QTY</th>
                            <th class="w-125px text-end">Total Hpp</th>
                            <th class="text-end">Status</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td class="w-200px">{{ item.created_at }}</td>
                            <td class="w-200px">{{ item.title }}</td>
                            <td class="w-200px">{{ item.kode_po }}</td>
                            <td class="w-200px">{{ item.kode_request_po }}</td>
                            <td class="w-200px">{{ item.warehouse }}</td>
                            <td>{{ item.nama_user }}</td>
                            <td class="text-end">{{ item.qty_final }}</td>
                            <td class="text-end">{{ formatIDR(item.hpp) }}</td>
                            <td class="text-end">
                              <span class="badge badge-dark" v-if="item.status == 'PENDING'">{{ item.status }}</span>
                              <span class="badge badge-info" v-if="item.status == 'DIKIRIM'">{{ item.status }}</span>
                              <span class="badge badge-primary" v-if="item.status == 'DITERIMA'">{{ item.status }}</span>
                              <span class="badge badge-success" v-if="item.status == 'SELESAI'">{{ item.status }}</span>
                            </td>
                            <td scope="col" class="text-end w-150px">
                                <div class="d-flex justify-content-end">
                                    <router-link :to="'/supplier/purchase_order_detail/'+item.kode_po" class="btn btn-primary btn-sm mx-1">
                                      View
                                    </router-link>
                                    <el-popconfirm
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        :title="'Apakah yakin ingin menghapus '+item.kode_po+'?'"
                                        @confirm="onDelete(item)"
                                        v-if="item.status == 'PENDING'"
                                        class="mx-1"
                                    >
                                        <template #reference>
                                            <button class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        title="Apakah yakin ingin mengirim purchase order?"
                                        @confirm="onSend(item)"
                                        v-if="item.status == 'PENDING'"
                                    >
                                        <template #reference>
                                            <button class="btn btn-info btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                Kirim
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        title="Selesaikan purchase order?"
                                        @confirm="onFinsih(item)"
                                        v-if="item.status == 'DIKIRIM'"
                                        class="mx-1"
                                    >
                                        <template #reference>
                                            <button class="btn btn-success btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" title="Selesaikan purchase order">
                                                Finish
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                    <router-link v-if="item.status == 'DIKIRIM' || item.status == 'SELESAI'" :to="'/supplier/print_purchase_order/'+item.kode_po" class="btn btn-secondary btn-sm mx-1">
                                      Print
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>

        <el-dialog v-model="modal" :title="modalTitle" width="600px" :close-on-click-modal="false">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 my-5"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled/>
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Nama</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="title"
                        :rules="[
                          { required: true, message: 'Name is required' },
                        ]"
                      >
                        <el-input v-model="tempData.title" name="title" class="form-control p-0 border-0" placeholder="Masukkan nama" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Parent</label>
                        <el-form-item
                          class="fv-row mb-7"
                          prop="parent_id"
                          :rules="[
                            { required: true, message: 'Parent is required' },
                          ]"
                        >
                          <el-select name="parent_id" v-model="tempData.parent_id" class="form-control p-0 border-0" placeholder="Pilih parent menu" size="large">
                            <el-option
                              :label="'Tidak Ada'"
                              :value="0"
                            >
                              <div class="d-flex">
                                <div class="col me-3">Tidak Ada</div>
                              </div>
                            </el-option>
                            <el-option
                              v-for="(item, i) in listParentMenu"
                              :key="i"
                              :label="item.title"
                              :value="item.id"
                            >
                              <div class="d-flex">
                                <div class="col me-3">{{ item.title }}</div>
                              </div>
                            </el-option>
                          </el-select>
                        </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Route</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="route"
                        :rules="[
                          { required: true, message: 'Route is required' },
                        ]"
                      >
                        <el-input v-model="tempData.route" name="route" class="form-control p-0 border-0" placeholder="Url routing" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Urutan</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="sequence"
                        :rules="[
                          { required: true, message: 'Sequence is required' },
                        ]"
                      >
                        <el-input v-model="tempData.sequence" name="sequence" class="form-control p-0 border-0" placeholder="Urutan menu" size="large"></el-input>
                      </el-form-item>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="button"
                        ref="submitButton"  
                        class="btn btn-primary"
                        :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                        @click="onSubmit(formRef, submitType)"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination, FormInstance, FormRules } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: { 
        //Datatable,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const loadingSubmit = ref(false);
        const tableRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })

        const formRef = ref(null)

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const listParentMenu = reactive([])

        const tempData = reactive(Yup.object().shape({
            id: null,
            title: Yup.string().required().label('Nama'),
            parent_id: Yup.number().required().label('Parent'),
            route: Yup.string().required().label('Route'),
            sequence: '',
            active: Yup.number().required().label('Status'),
        }))
        
        const submitType = ref('')

        const modal = ref(false)
        const modalTitle = ref('')
        
        const modalClick = async(type, data = null) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                submitType.value = type

                tempData.id = null
                tempData.title = ''
                tempData.parent_id = ''
                tempData.route = ''
                tempData.icon = ''
                tempData.sequence = ''
                tempData.active = ''

                await parentMenu()

                if(type == 'add') {
                    modalTitle.value = 'Add New Menu'
                    tempData.active = 1
                }
                if(type == 'edit') {
                    await Object.assign(tempData, data);
                    modalTitle.value = 'Edit Menu'
                }

                modal.value = true

            } catch(e) {

                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const onFilter = async (type) => {
            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const parentMenu = async () => {
            try {
                await listParentMenu.splice(0)

                await ApiService.get("parent_menu")
                .then(async ({ data }) => {
                    Object.assign(listParentMenu, data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting parent menu');
                });

            } catch(e) {
                console.error(e)
            }
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("supplier/purchase_order?page=" + page + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {
                    if(response) {
                        throw new Error(response)
                    }
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const onSubmit = async(formEl, type) => {
            try {
                if (!formEl) return

                await formEl.validate( async(valid, fields) => {
                    loadingSubmit.value = true;

                    if(valid) {
                        let endpoint = '';

                        if(type == 'add') {
                            endpoint = '/menu/create';
                        }
                        if(type == 'edit') {
                            endpoint = '/menu/update/'+tempData.id;
                        }

                        await ApiService.post(endpoint, JSON.parse(JSON.stringify(tempData)))
                        .then(async({ data }) => {
                            if(data.error) {
                                throw new Error(data.messages)
                            }

                            modal.value = false

                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })

                            await getData()
                        })
                        .catch(({ response }) => {
                            if(response.data.messages) { 
                                if(typeof response.data.messages === 'object')
                                    throw Error(Object.entries(response.data.messages).map(([k, v]) => `${v}`).join(', \n'));
                                else 
                                    throw Error(response.data.messages);
                            }
                            
                        });

                    } else {
                        throw new Error('Please check form before submit.')
                    }

                    loadingSubmit.value = false

                })

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const onDelete = async(row) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                await ApiService.post("supplier/delete_purchase_order/"+row.kode_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const onSend = async(row) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                await ApiService.post("supplier/send_purchase_order/"+row.kode_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const onFinsih = async(row) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                await ApiService.post("supplier/finish_purchase_order/"+row.kode_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    if(response.data) {
                        Swal.fire({
                            title: response.data.messages,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = data.logo
            viewImageVisible.value = true
            viewImageSrc.value = img_url_logo.value+data.logo
        }

        watch(() => tempData.name, (val) => {
            if(val) {
                let splitName = val.split(" ");
                
                if(submitType.value == 'add') {
                    if(splitName.length > 1) {
                        tempData.shortname = splitName.join("_").toLowerCase();
                    } else {
                        tempData.shortname = val.toLowerCase();
                    }
                }
            }
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(route.meta.title, ['Supplier', route.meta.title]);

            getData()
        })

        return {
            formatIDR,
            loadingTable, loadingSubmit, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            modal, modalTitle, modalClick,
            pagination, handlePageChange,
            onFilter, resetFilter, filter, filterActive,
            formRef,
            tableData, tempData, listParentMenu, submitType, onSubmit, onDelete, onSend, onFinsih,
        }
    }
})
</script>